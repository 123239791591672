import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Particles from 'react-particles-js';
import ParticlesOptions from './options';
import logo from './logo.png';
import './App.css';
import TypeWriter from './components/TypeWriter';

const App = () => {
  React.useEffect(() => {
    ReactGA.initialize('UA-161743284-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className="wrapper">
      <div className='particles'>
        <Particles params={ParticlesOptions} />
      </div>
      <div className='landing'>
        <div className='intro'>
          <img src={logo} alt="Sematics Limited"/>
        </div>
        <div className='header-content'>
          <a href="https://sematics.io" className='login-button'>
            <span className="arrow">→</span>
          </a>
        </div>
        <div className='register'>
          <p>All rights reserved. Copyright © 2025 Sematics Limited - Company No. 11837448</p>
        </div>
      </div>
    </div>
  );
}

export default App;


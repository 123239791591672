import React, { useState, useEffect } from 'react';

const TypeWriter = ({ strings, colorClass, typingSpeed = 150, deletingSpeed = 100, pauseTime = 2000 }) => {
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [stringIndex, setStringIndex] = useState(0);

  useEffect(() => {
    const handleTyping = () => {
      const currentString = strings[stringIndex];
      
      if (isDeleting) {
        setText(currentString.substring(0, text.length - 1));
        
        if (text.length === 0) {
          setIsDeleting(false);
          setStringIndex((prev) => (prev + 1) % strings.length);
        }
      } else {
        setText(currentString.substring(0, text.length + 1));
        
        if (text.length === currentString.length) {
          setTimeout(() => setIsDeleting(true), pauseTime);
          return;
        }
      }
    };

    const timer = setTimeout(
      handleTyping,
      isDeleting ? deletingSpeed : typingSpeed
    );

    return () => clearTimeout(timer);
  }, [text, isDeleting, stringIndex, strings, typingSpeed, deletingSpeed, pauseTime]);

  return <span className={colorClass}>{text}</span>;
};

export default TypeWriter; 